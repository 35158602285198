#cursor {
    border-left: 2px solid #ff4e0d;
    animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
